import React from 'react'
import {graphql} from 'gatsby'

import Layout from '../components/Layout'
import {MarkdownContent} from '../components/Content'

export const PaymentsPageTemplate = ({hero, services}) => {
  return (
    <>
      <section id='hero' className='hero is-dark-brown'>
        <div className='hero-body'>
          <div className='container'>
            <div className='section'>
              <h1 className='title is-1 has-text-white'>{hero.heading}</h1>
            </div>
          </div>
        </div>
      </section>
      <section className='section payments-message'>
        <div className='container is-max-desktop'>
          <MarkdownContent content={hero.description} />
        </div>
      </section>
      <section className='section'>
        <div className='container is-max-desktop'>
          <div className='payments-container'>
            {services.map(
              ({title, subtitle, amount, btnLink, btnText}, idx) => (
                <div className='box is-flex is-flex-direction-column' key={idx}>
                  <h3 className='title is-3 is-2-mobile has-text-white mb-0'>
                    <MarkdownContent content={title} />
                  </h3>
                  {subtitle && (
                    <MarkdownContent
                      content={subtitle}
                      className='has-text-info-light'
                    />
                  )}
                  <p className='mb-5'>{amount}</p>
                  <a
                    className='theme-btn'
                    href={btnLink}
                    target='_blank'
                    rel='noreferrer'
                  >
                    {btnText}
                  </a>
                </div>
              )
            )}
          </div>
        </div>
      </section>
    </>
  )
}

const PaymentsPage = ({data}) => {
  const {frontmatter} = data.markdownRemark

  return (
    <Layout>
      <PaymentsPageTemplate
        hero={frontmatter.hero}
        services={frontmatter.services}
      />
    </Layout>
  )
}

export default PaymentsPage

export const pageQuery = graphql`
  query PaymentsPageTemplate {
    markdownRemark(frontmatter: {templateKey: {eq: "payments-page"}}) {
      frontmatter {
        hero {
          heading
          description
        }
        services {
          title
          subtitle
          amount
          btnText
          btnLink
        }
      }
    }
  }
`
